const styles = {
  container: '',
  button:
    '!text-xs !text-white font-display !uppercase !p-0 border-none ring-none -mr-4 cursor-pointer',
  icon: '!text-[0.5rem]',

  item: '!text-xs',
};

export default styles;
