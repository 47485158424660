import { z } from 'zod';

const MAX_FILE_SIZE = 500000;
const ACCEPTED_FILE_TYPES = ['application/pdf', 'application/msword'];

const validation = {
  object: z.object,
  string: z.string,
  number: z.number,
  enum: z.enum,
  any: z.any,
  //
  nonEmptyString: (message: string) => {
    return z.string({ required_error: message }).min(1, { message: message });
  },
  nonEmptyStringOptional: () => z.string().isOptional(),
  cv: () =>
    z
      .any()
      .refine((files) => files?.length == 1, 'Image is required.')
      .refine(
        (files) => files?.[0]?.size <= MAX_FILE_SIZE,
        'Max file size is 5MB.',
      )
      .refine(
        (files) => ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
        '.jpg, .jpeg, .png and .webp files are accepted.',
      ),
};

export default validation;
