const styles = {
  container: '',
  button:
    '!text-xs !text-primary-500 !uppercase !p-0 border-none ring-none -mr-4',
  icon: '!text-[0.5rem] -translate-y-0.5',

  item: '!text-xs',
};

export default styles;
