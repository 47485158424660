import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import validation from 'helpers/validation';

export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(' ');
};

export const handleWait = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const handleConvertFormDataToObject = <T>(formData: FormData) => {
  let data = {};
  const keys = formData.keys();
  for (const key of keys) {
    data = { ...data, [key]: formData.get(key) };
  }
  return data as T;
};

export type TFormSectionField = {
  id: string;
  type: string;
  label: string;
  placeholder: string;
  description: string;
  options?: { label: string; value: string }[];
  defaultValue?: string | number | undefined;
  isOptional?: boolean;
};

type TFormSection = {
  id: string;
  title: string;
  fields: TFormSectionField[];
};

export type TFormStructure = {
  sections: TFormSection[];
};

export const handleGenerateSchemaFromFormStructure = (
  structure: TFormStructure | undefined,
  { required, invalidEmail }: { required: string; invalidEmail: string },
) => {
  const fieldsFlatList = structure?.sections.flatMap(
    (section) => section.fields,
  );

  let result = {};
  fieldsFlatList?.forEach((field) => {
    let value;

    switch (field.type) {
      case 'text':
      case 'area':
        value = validation.nonEmptyString(required);
        break;
      case 'email':
        value = validation.string().email({ message: invalidEmail });
        break;
      case 'file':
        value = validation.cv();
        break;
      case 'select':
        value = validation.enum(
          field.options?.map((option) => option.value) as any,
        );
        break;
    }

    result = {
      ...result,
      [field.id]: value,
    };
  });

  return validation.object({ ...result });
};

export const handleGenerateDefaultValuesFromFormStructure = (
  structure?: TFormStructure,
  slug?: string,
) => {
  const fieldsFlatList = structure?.sections.flatMap(
    (section) => section.fields,
  );

  let result = {};
  fieldsFlatList?.forEach((field) => {
    let value;

    switch (field.type) {
      case 'text':
      case 'area':
        value = field.defaultValue ?? '';
        break;
      case 'email':
        value = field.defaultValue ?? '';
        break;
      case 'file':
      case 'select':
        if (field.id === 'branchSlug') {
          value = slug;
        } else {
          value = field.defaultValue ?? undefined;
        }
        break;
    }

    result = {
      ...result,
      [field.id]: value,
    };
  });

  return result;
};
