const styles = {
  container: 'bg-black py-6 sm:py-8 !text-white z-10 border-b border-gray-850',
  content: 'flex flex-row items-center justify-between',

  head: {
    container: 'flex flex-row items-center gap-x-4 sm:gap-x-6',
  },

  foot: {
    container: 'flex flex-row items-center gap-x-4 sm:gap-x-6',
    list: 'flex items-center gap-x-8',
  },

  figure: {
    small: 'sm:hidden',
    big: 'hidden sm:block',
  },

  icon: 'w-2',
  button:
    '!text-sm !text-primary-500 font-semibold font-display hover:bg-primary-500 hover:!text-black',

  alert: {
    container: 'bg-primary-500 w-full z-20 py-1',
    paragraph: 'text-xs py-1',
  },
};

export default styles;
