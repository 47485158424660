'use client';

import { useParams, usePathname } from 'next/navigation';
import React from 'react';

import { TNextClientComponent } from 'types/next';

import { useTranslation } from 'hooks/useTranslation';

import Icon from 'components/custom/Icon';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from 'components/tailwind-catalyst/Dropdown';

import { styles } from '.';

type TProps = {
  lang?: string;
};

const LocationPicker: TNextClientComponent<TProps> = () => {
  const params = useParams();
  const pathname = usePathname();

  const { t } = useTranslation(params?.lang as string);

  const locations = [
    {
      id: 'brussels',
      label: t('data.branch.BRUSSELS.title'),
      href: pathname?.replace(params?.slug as string, 'brussels'),
    },
    {
      id: 'ghent',
      label: t('data.branch.GHENT.title'),
      href: pathname?.replace(params?.slug as string, 'ghent'),
    },
    {
      id: 'bruges',
      label: t('data.branch.BRUGES.title'),
      href: pathname?.replace(params?.slug as string, 'bruges'),
    },
    {
      id: 'ostend',
      label: t('data.branch.OSTEND.title'),
      href: pathname?.replace(params?.slug as string, 'ostend'),
    },
  ];

  const allowedSlugs = locations.map((location) => location.id);

  if (!params?.slug || !allowedSlugs.includes(params?.slug as string)) {
    return <div></div>;
  }

  return (
    <Dropdown>
      <DropdownButton outline className={styles.button}>
        {t(`data.branch.${(params?.slug as string)?.toUpperCase()}.title`)}
        <Icon accessor="chevron-down" className={styles.icon} size="8px" />
      </DropdownButton>
      <DropdownMenu>
        {locations.map((item) => (
          <DropdownItem key={item.id} href={item.href} className={styles.item}>
            {item.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default LocationPicker;
